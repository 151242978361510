<template>
    <div class="security-content">
        <div class="head-con">
            <h3>账号安全</h3>
        </div>

        <div class="content">
            <div class="section">
                <div style="display:flex;align-items:center;">
                    <el-icon color="#4184E8" size="30"><lock /></el-icon>
                    <div class="section-con">
                        <div class="title">登录密码</div>
                        <p>定期更换密码有助于账号安全</p>
                    </div>
                </div>
                <el-button size="mini" @click="modifyCode">修改</el-button>
            </div>
            <div class="section">
                <div style="display:flex;align-items:center;">
                    <img src="@/assets/image/security_pwd.png" class="img"/>
                    <div class="section-con">
                        <div class="title">支付密码</div>
                        <p v-if="password_staus">已设置支付密码</p>
                        <p v-else>暂未设置支付密码</p>
                    </div>
                </div>
                <el-button size="mini" @click="setPassword">设置</el-button>
            </div>
            <div class="section">
                <div style="display:flex;align-items:center;">
                    <el-icon color="#4184E8" size="30"><iphone /></el-icon>
                    <div class="section-con">
                        <div class="title">手机号码</div>
                        <p>您验证的手机:{{mobile}}</p>
                    </div>
                </div>
                <el-button size="mini" @click="modifyPhone">修改</el-button>
            </div>
        </div>

        <el-dialog width="35%" v-model="dialog1" title="修改登录密码">
            <el-form :form="passwordform" label-width="100px">
                <el-form-item label="原密码">
                    <el-input v-model="passwordform.y_password" placeholder="请输入原密码" size="small" show-password></el-input>
                </el-form-item>
                <el-form-item label="新密码">
                    <el-input v-model="passwordform.x_password" placeholder="请输入新密码" size="small" show-password></el-input>
                </el-form-item>
                <el-form-item label="确认密码">
                    <el-input v-model="passwordform.x_password1" placeholder="请再次输入新密码" size="small" show-password></el-input>
                </el-form-item>
                <el-form-item label="验证码">
                    <div style="display:flex;align-items:center;">
                        <el-input v-model="passwordform.code" placeholder="图片验证码" size="small" style="width:300px;"></el-input>
                        <img :src="imgCode.code_img" />
                    </div>
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="foot-con">
                    <el-button @click="dialog1 =false" size="mini">取消</el-button>
                    <el-button type="danger" @click="toSavepwd" size="mini">保存</el-button>
                </div>
            </template>
        </el-dialog>

        <el-dialog width="35%" v-model="dialog2" title="修改支付密码">
            <el-form :form="paypwdForm" label-width="100px">
                <el-form-item label="手机号">
                    <p>{{paypwdForm.phoneNum}}</p>
                </el-form-item>
                <el-form-item label="验证手机">
                    <el-input v-model="paypwdForm.keyCode" placeholder="手机验证码" size="small">
                        <template #append>
                           <el-button @click="getMobileCode" :disabled="btnDesc !='获取验证码'">{{btnDesc}}</el-button>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item label="新密码">
                    <el-input v-model="paypwdForm.password0" placeholder="请输入密码" size="small" show-password></el-input>
                </el-form-item>
                <el-form-item label="确认密码">
                    <el-input v-model="paypwdForm.password1" placeholder="请再次输入新密码" size="small" show-password></el-input>
                </el-form-item>


                <el-form-item label="验证码">
                    <div style="display:flex;align-items:center;">
                        <el-input v-model="passwordform.code" placeholder="图片验证码" size="small" style="width:300px;"></el-input>
                        <img :src="imgCode.code_img" />
                    </div>
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="foot-con">
                    <el-button @click="dialog2 =false" size="mini">取消</el-button>
                    <el-button type="danger" @click="ChangePwd" size="mini">保存</el-button>
                </div>
            </template>
        </el-dialog>

        <el-dialog width="35%" v-model="dialog3" title="修改手机号码">

            <el-steps :active="active" finish-status="success">
                <el-step title="身份验证"></el-step>
                <el-step title="手机修改"></el-step>
                <el-step title="修改完成"></el-step>
            </el-steps>
            <el-form :form="form3" label-width="100px">
                <el-form-item label="原手机">
                    <p>{{form3.mobile}}</p>
                </el-form-item>
                <el-form-item label="验证手机">
                    <el-input v-model="form3.keyCode" placeholder="手机验证码" size="small">
                        <template #append>
                            <el-button @click="getMobileCode">{{btnDesc}}</el-button>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item label="验证码">
                    <div style="display:flex;align-items:center;">
                        <el-input v-model="passwordform.code" placeholder="图片验证码" size="small" style="width:300px;"></el-input>
                        <img :src="imgCode.code_img" />
                    </div>
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="foot-con">
                    <el-button type="danger" @click="setPhone" size="mini">下一步</el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import { Lock,Iphone} from '@element-plus/icons'
import {onMounted,reactive,ref, toRefs} from 'vue'
import {getData} from '@/utils/req'
import { ElMessage } from 'element-plus'
export default {
    components:{
        Lock,
        Iphone
    },
    setup(){
        let pc_user = ref('')
        let active = ref(0)
        let dialog1 = ref(false)
        let dialog2 = ref(false)
        let dialog3 = ref(false)
        let state = reactive({
            mobile:'',
            password_staus:'',
            user_id:'',
            y_mobile:''
        })
        let passwordform = reactive({
            y_password:'',
            x_password:'',
            x_password1:'',
            code:''
        })
        let timeout = ref('')
        let btnDesc = ref('获取验证码')
        let paypwdForm = reactive({
            phoneNum:'', 
            keyCode:'',
            password0:'', 
            password1:''
        })

        let form3 = reactive({
            mobile:'',
            phone:'',
			keyCode:''
        })
        let imgCode = reactive({
            code_img:'',
            code:''
        })
        onMounted(()=>{
            pc_user.value = JSON.parse(localStorage.getItem('pc_user'))
            axios()
        })
        const get_img_code =()=>{
            const data ={
                module: 'app_pc',
                action: 'login',
                m: 'get_code'
            }
            getData(data).then(res=>{
                imgCode = Object.assign(imgCode,res.data)
                imgCode.code_img = 'https://admin.iseso.net//'+ imgCode.code_img
            })
        }
        const axios = ()=>{
            const data= {
                module: 'app_pc',
                action: 'user',
                m: 'account_security',
                access_id:pc_user.value.access_id 
            }
            getData(data).then(res=>{
                state = Object.assign(state,res.data)
                paypwdForm.phoneNum = state.y_mobile
                form3.mobile = state.mobile,
                form3.phone = state.y_mobile
            })
        }
        const modifyCode = ()=>{
            dialog1.value = true
            get_img_code()
        }
        const getMobileCode =()=>{
            const data ={
                module: 'app_pc',
                action: 'user',
                m: 'secret_key',
                access_id:pc_user.value.access_id,
                phone: paypwdForm.phoneNum,
                message_type: 0, 
                message_type1: 3 
            }
            getData(data).then(res=>{
                if(res.code ==200){
                    ElMessage.success({
                        message:res.message,
                        duration:1500
                    })
                    countDown(60)
                }
            })
        }
        const countDown =(i)=>{
            i--
            btnDesc.value = `${i}秒后可重新获取`
            if(i<1){
                btnDesc.value = '获取验证码'
            }
            timeout.value =setTimeout(()=>{
                countDown(i)
            },1000)
        }
        const toSavepwd =()=>{
            if(!passwordform.y_password){
                ElMessage.info({
                    message:"原密码不能为空",
                    duration:1500
                })
                return
            }
            if(!passwordform.x_password){
                ElMessage.info({
                    message:'新密码不能为空',
                    duration:1500
                })
                return
            }
            if(!passwordform.x_password1){
                ElMessage.info({
                    message:'确认密码不能为空',
                    duration:1500
                })
                return
            }
            if(passwordform.code.toUpperCase() !== imgCode.code.toUpperCase()){
                ElMessage.info({
                    message:'验证码错误',
                    duration:1500
                })
                return
            }
            delete passwordform.code
            let data ={
                module: 'app_pc',
                action: 'user',
                m: 'updatepassword',
                access_id:pc_user.value.access_id
            }
            data = Object.assign(data,passwordform)
            getData(data).then(res=>{
                if(res.code ==200){
                    dialog1.value = false
                    ElMessage.success({
                        message:'密码修改成功',
                        duration:1500
                    })
                }
            })
        }
        const setPassword =()=>{
            dialog2.value = true
            get_img_code()
        }
        const resetTemp = (obj) =>{
            for(let i in obj){
                obj[i] = ''
            }
        }
        const ChangePwd =()=>{
            if(!paypwdForm.keyCode){
                ElMessage.info({
                    message:'手机验证码不能为空',
                    duration:1500
                })
                return
            }
            if(!paypwdForm.password0){
                ElMessage.info({
                    message:"新密码不能为空",
                    duration:1500
                })
                return
            }
            if(!paypwdForm.password1){
                ElMessage.info({
                    message:'确认密码不能为空',
                    duration:1500
                })
                return
            }
            if(paypwdForm.password0.toUpperCase() !== paypwdForm.password1.toUpperCase()){
                ElMessage.info({
                    message:'两次输入密码不一致',
                    duration:1500
                })
                return
            }
            if(passwordform.code.toUpperCase() !== imgCode.code.toUpperCase()){
                ElMessage.info({
                    message:'验证码错误',
                    duration:1500
                })
                return
            }
            let data ={
                module:'app_pc',
                action:'user',
                m:'set_payment_password',
                access_id:pc_user.value.access_id,
            }
            data = Object.assign(data,paypwdForm)
            getData(data).then(res=>{
                if(res.code == 200){
                    ElMessage.success({
                        message:res.message,
                        duration:1500
                    })
                    dialog2.value = false
                    resetTemp(paypwdForm)
                }
            })
        }
        const modifyPhone =()=>{
            dialog3.value = true
            get_img_code()
        }
        const getAuth = ()=>{
            if(!form3.keyCode){
                ElMessage.info({
                    message:'手机验证码不能为空',
                    duration:1500
                })
                return
            }
            if(!passwordform.code){
                ElMessage.info({
                    message:'图片验证码不能为空',
                    duration:1500
                })
                return
            }
            if(passwordform.code.toUpperCase() !== imgCode.code.toUpperCase()){
                ElMessage.info({
                    message:'验证码错误',
                    duration:1500
                })
                return
            }
            delete form3.mobile
            let data ={
                module: 'app_pc',
                action: 'user',
                m: 'Authentication',
                access_id:pc_user.value.access_id,
            }
            data = Object.assign(data,form3)
            console.log(data)
        }
        const setPhone = ()=>{
            if(active.value ==0){
                getAuth()
            }
        }
        return{
            dialog1,
            dialog2,
            dialog3,
            active,
            btnDesc,
            ...toRefs(state),
            passwordform,
            paypwdForm,
            form3,
            imgCode,
            modifyCode,
            get_img_code,
            toSavepwd,
            setPassword,
            getMobileCode,
            ChangePwd,
            modifyPhone,
            setPhone
        }
    }
}
</script>
<style scoped>
    .security-content{
        background: #fff;
        width: 100%;
        height: 100%;
        position: relative;
    }
    .security-content .head-con{
        width: 100%;
        height: 56px;
        border-bottom: 1px solid #E6E6E6;
    }
    .security-content .head-con h3{
        color: #020202;
        font-size: 16px;
        font-weight: bold;
        padding-left: 16px;
        line-height: 56px;
    }
    .content{
        width: 100%;
    }
    ::v-deep .el-dialog__header{
        border-bottom: 1px solid #e6e6e6;
    }
    ::v-deep .el-dialog__title{
        color: #000;
        font-weight: bold;
        font-weight: 16px;
    }
    ::v-deep .el-dialog__footer{
        height: 68px;
        background: #f6f6f6;
    }
    .foot-con .el-button{
        width: 112px;
        height: 36px;
        border-radius: 0px;
    }
    .content .section{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 20px;
        padding: 30px 0;
        border-bottom:1px solid #eee;
    }
    .section-con{
        margin-left: 10px;
    }
    .section .section-con .title{
        font-size: 16px;
        line-height: 16px;
        color: #020202;
    }
    .section .section-con p{
        display: block;
        font-size: 13px;
        line-height: 13px;
        color: #999999;
        margin-top: 12px;
    }
    .section .el-button{
        border-radius: 0;
        width: 80px;
        height: 30px;
    }
    .section .img{
        width: 28px;
    }
</style>